import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchSegmentationFormStore = defineStore('searchSegmentationFormStore', {
  state: () => ({
    SegmentationForm_id: null as number | null,
    SegmentationForm_status: null as number | null,
    SegmentationForm_periodeYear: null as string | null,
    SegmentationForm_periodeMonth: null as string | null,
    SegmentationForm_validUntil: null as string | null,
    SegmentationForm_correction: null as string | null,
    SegmentationForm_code: null as string | null,

    items: [
      { key: 'SegmentationForm_id', operation: ':' },
      { key: 'SegmentationForm_status', operation: ':' },
      { key: 'SegmentationForm_periodeYear', operation: ':' },
      { key: 'SegmentationForm_periodeMonth', operation: ':' },
      { key: 'SegmentationForm_validUntil', operation: ':' },
      { key: 'SegmentationForm_correction', operation: ':' },
      { key: 'SegmentationForm_code', operation: ':' }
    ] as TermItemDefinition[]
  })
})
