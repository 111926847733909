<template>
  <div>
    <h2 v-html="$t('search_segmentation_forms_card_title')"></h2>
    <!--Meldungs-ID -->
    <v-text-field
      id="search_segmentation_forms_report_id"
      v-model="SearchSegmentationFormStore.SegmentationForm_id"
      :label="$t('search_segmentation_forms_report_id')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Status -->
    <v-select
      id="search_segmentation_forms_status"
      v-model="SearchSegmentationFormStore.SegmentationForm_status"
      :label="$t('search_segmentation_forms_status')"
      :items="segmentationStatuses"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Monats Mengenmeldung von -->
    <DbmMonthPicker
      id="search_segmentation_forms_completon_form"
      v-model="SearchSegmentationFormStore.SegmentationForm_validUntil"
      :dateLabel="$t('search_segmentation_forms_month_quantity_report')"
      :clearable="true"
    />
    <!-- Korrektur -->
    <v-select
      id="search_segmentation_forms_correction"
      v-model="SearchSegmentationFormStore.SegmentationForm_correction"
      :label="$t('search_segmentation_forms_correction')"
      :items="segmentation_form_is_correction"
      item-title="text"
      item-value="value"
      clearable
      @keyup.enter="search"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Korrespondenzweg -->
      <v-select
        id="search_dairy_reporting_obligation_BOM"
        v-model="SearchDairyStore.Dairy_segmentationReportingObligation"
        :label="$t('search_segmentation_forms_reporting_obligation')"
        :items="reportingObligations"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
      />
      <!-- Ablage-Nr. -->
      <v-text-field
        id="search_segmentation_forms_depot_number"
        v-model="SearchSegmentationFormStore.SegmentationForm_code"
        :label="$t('search_segmentation_forms_depot_number')"
        type="text"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'

import { useSearchSegmentationFormStore } from '@/store/SearchSegmentationFormStore'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useSegmentationStatusStore } from '@/store/enums/SegmentationStatusStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingForm',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      segmentation_form_is_correction: [
        { value: 1, text: this.$t('search_processing_form_is_correction') },
        { value: 0, text: this.$t('search_processing_form_is_not_correction') }
      ],
      menuTo: false
    }
  },
  computed: {
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    },
    segmentationStatuses() {
      return useSegmentationStatusStore().segmentationStatuses
    },
    SearchSegmentationFormStore() {
      return useSearchSegmentationFormStore()
    },
    SearchDairyStore() {
      return useSearchDairyStore()
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  methods: {
    reset() {
      this.resetFields(this.SearchSegmentationFormStore)
      this.resetFields(this.SearchDairyStore)
    }
  },
  beforeMount() {
    useReportingObligationsStore().fetchReportingObligations()
    useSegmentationStatusStore().fetchSegmentationStatuses()
    this.initSearchStoreFromQuery(this.SearchSegmentationFormStore, this.$route.query)
    this.initSearchStoreFromQuery(this.SearchDairyStore, this.$route.query)
  }
})
</script>
